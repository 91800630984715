import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import ProductCard from "../components/product-card"
import Seo from "../components/seo"

const ProduitsPage = ({
  data: {
    allMarkdownRemark: { edges },
  },
}) => {
  const Products = edges.map(edge => (
    <ProductCard
      className="col-md-6 d-flex flex-column"
      key={edge.node.id}
      product={edge.node}
    />
  ))
  return (
    <Layout>
      <Seo title="Produits" />
      <h2>Nos produits</h2>
      <div className="row mb-5">{Products}</div>
      <div className="alert alert-vf mb-5">
        <p>
          N’hésitez pas à <a href="/contact">nous contacter</a> si vous ne
          trouvez pas ce que vous cherchez dans nos produits.
        </p>
        <p>Nous réalisons des mélanges et volumes personnalisé sur demande.</p>
      </div>
    </Layout>
  )
}

export default ProduitsPage

export const pageQuery = graphql`
  query {
    allMarkdownRemark(filter: { fileAbsolutePath: { regex: "/products/" } }) {
      edges {
        node {
          id
          frontmatter {
            title
            category
            variants_label
            variants
            volumes
            image {
              childImageSharp {
                fluid(maxWidth: 600) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`
