import React from "react"

const ProductCard = ({ product, className }) => (
  <div className={className}>
    <h3>{product.frontmatter.title}</h3>
    <div className="card h-100 p-3 mb-5">
      <div className="row h-100">
        <div className="col-6 d-flex flex-column">
          <h4>{product.frontmatter.variants_label}</h4>
          <ul>
            {product.frontmatter.variants.map((variant, i) => (
              <li key={i}>{variant}</li>
            ))}
          </ul>
          <h4 className="mt-auto">Volumes disponibles:</h4>
          <p>{product.frontmatter.volumes}</p>
        </div>
        <div className="col-6 d-flex flex-column">
          <img
            src={product.frontmatter.image.childImageSharp.fluid.base64}
            width="100%"
            data-src={product.frontmatter.image.childImageSharp.fluid.src}
            data-srcset={product.frontmatter.image.childImageSharp.fluid.srcSet}
            data-sizes={product.frontmatter.image.childImageSharp.fluid.sizes}
            className="lazyload mt-auto"
            alt={product.frontmatter.title}
          />
        </div>
      </div>
    </div>
  </div>
)
export default ProductCard
